import type { Provider } from '@vgtv/api-client';
import type { Config } from '@schibsted-svp/web-player';

import type {
  AdnContext,
  PlayerConfigOptions,
  PlayerConfigPaywall,
} from '../types/global';
import { areAirplayAdsEnabled } from '../utils/airplayAds';

import getUserId from './adn/getUserId';
import getAdnSettingsVgtv from './adn/vgtv/getAdnSettings';
import getAdnSettingsAb from './adn/ab/getAdnSettings';
import getPlayerToken from './paywall/getPlayerToken';
import * as SPiD from './paywall/spid';
import { defaultConfig, defaultOptions } from './defaults';
import { getPluginUrl, getSkinUrl } from './helpers/staticUrls';
import { createPulseDecorator } from './pulse';
import { withPPID } from './adn/ppid';
import { getConsentManager } from './adn/privacy';
import { AutoplayManager } from './adn/ab/AutoplayManager';

export type SchibstedAccount = typeof SPiD & { env: 'PRE' | 'PRO' | 'PRO_NO' };

export default function getPlayerConfig(
  options: Partial<PlayerConfigOptions>,
  config: Config
): Config {
  // eslint-disable-next-line no-param-reassign
  options = {
    ...defaultOptions,
    ...options,
    plugins: {
      ...defaultOptions.plugins,
      ...options.plugins,
    },
    ads: {
      ...defaultOptions.ads,
      ...options.ads,
    },
  };

  const settings = {
    ...defaultConfig.settings,
    ...config.settings,
    jw: { ...defaultConfig?.settings?.jw, ...config?.settings?.jw },
  };

  const paywallAccess: PlayerConfigPaywall = {
    ...defaultOptions.paywallAccess,
    ...options.paywallAccess,
    message: {
      ...defaultOptions.paywallAccess?.message,
      ...options.paywallAccess?.message,
    },
  };

  const experiments = options.experiments ?? [];

  const vendor = (config.vendor || defaultConfig.vendor) as Provider;

  // eslint-disable-next-line no-param-reassign
  config.skin = {
    ...(vendor === 'vgtv'
      ? defaultConfig.skin
      : {
          name: 'vgtv',
          url: getSkinUrl('vgtv'),
        }),
    ...config.skin,
  };

  const configOverrides: Partial<Config> & {
    disableCountdownPlugin?: boolean;
  } = {};

  const consentManager = getConsentManager(vendor);
  const adnContext: AdnContext = {
    consentManager,
    autoplayManager: vendor === 'ab' ? new AutoplayManager() : undefined,
  };

  let plugins: Config['plugins'] = {};

  if (config.plugins) {
    plugins = { ...config.plugins };
  } else if (options.plugins?.mediaSession) {
    plugins[getPluginUrl('media-session')] = {
      name: 'MediaSessionPlugin',
    };
  }

  if (vendor === 'vgtv') {
    plugins[getPluginUrl('adn')] = {
      name: 'AdnPlugin',
      options: {
        options,
        context: adnContext,
      },
    };
  }

  if (config.skin?.name === 'vgtv2') {
    let schibstedAccountEnv: 'PRE' | 'PRO' | 'PRO_NO';

    if (paywallAccess.usePre) {
      schibstedAccountEnv = 'PRE';
    } else {
      schibstedAccountEnv = vendor === 'vgtv' ? 'PRO_NO' : 'PRO';
    }

    const recommended: Config['recommended'] =
      typeof config.recommended === 'boolean'
        ? {}
        : {
            ...defaultConfig.recommended,
            ...config.recommended,
          };

    const recommendedAutoplay = Boolean(
      recommended.autoplay || recommended.grid || recommended.next
    );

    plugins[getPluginUrl('skin-vgtv2')] = {
      name: 'VgtvSkinPlugin',
      options: {
        schibstedAccount: { ...SPiD, env: schibstedAccountEnv },
        context: options.context ?? 'embed',
        playlist: options.playlist,
        ui: options.ui,
        experiments,
        recommended: {
          autoplay: recommendedAutoplay,
        },
        utmParameters: paywallAccess.message?.utmParameters,
        onLoginClick:
          paywallAccess.message?.onLoginClick ||
          (() => {
            if (paywallAccess.message?.onRedirect instanceof Function) {
              paywallAccess.message.onRedirect();
            }

            SPiD.login();
          }),
      },
    };

    SPiD.initIdentity(paywallAccess.getSpidIdentityInstance);
    SPiD.initMonetization(
      paywallAccess.getSpidMonetizationInstance,
      vendor,
      schibstedAccountEnv
    );

    Object.assign(settings.jw, {
      controls: false,
      displaytitle: false,
      fullscreenOrientationLock: 'none',
      plugins: {
        [getPluginUrl('chromecast')]: {
          vendor,
          site: options.site,
          externalUid: getUserId(),
          consentManager,
        },
      },
    });

    configOverrides.recommended = false;
    configOverrides.disableCountdownPlugin = true;
  }

  if (
    options.plugins?.topicSubscriber &&
    vendor === 'vgtv' &&
    paywallAccess.getSpidIdentityInstance
  ) {
    plugins[getPluginUrl('topic-subscriber')] = {
      name: 'TopicSubscriberPlugin',
      options: { getUserSettings: SPiD.getUserSettings, consentManager },
    };

    SPiD.initIdentity(paywallAccess.getSpidIdentityInstance);
  }

  if (options.plugins?.sponsorOverlay) {
    plugins[getPluginUrl('sponsor-overlay')] = {
      name: 'SponsorOverlayPlugin',
    };
  }

  if (
    (vendor === 'ab' && config.skin?.name !== 'vgtv2') ||
    (config.skin?.name === 'vgtv2' &&
      // chromecast vs airplay
      (window.chrome || areAirplayAdsEnabled()))
  ) {
    // @ts-expect-error this is fine
    settings.jw.cast = undefined;
  }

  return {
    ...defaultConfig,
    ...config,
    sharing: false,
    settings,
    plugins,
    recommended: {
      ...defaultConfig?.recommended,
      ...(typeof config.recommended === 'boolean' ? {} : config.recommended),
    },
    pulse: {
      ...defaultConfig.pulse,
      ...config.pulse,
      decorator: createPulseDecorator(
        vendor,
        // PulseProvider handles it for vgtv2. Legacy player only
        Boolean(options.paywallAccess && config.skin?.name !== 'vgtv2'),
        adnContext,
        SPiD.getUserIdAsync,
        SPiD.getUserAccesses,
        config.pulse?.decorator
      ),
      sdkConfig: {
        requireAdvertisingOptIn:
          consentManager.type === 'tcf' ? true : undefined,
        consents: consentManager.getCachedOrDefaultConsentsForPulse(),
      },
    },
    adn: withPPID(vendor === 'ab' ? getAdnSettingsAb : getAdnSettingsVgtv).bind(
      null,
      adnContext,
      options
    ) as Config['adn'],
    token: options.paywallAccess?.enabled
      ? (getPlayerToken(paywallAccess, vendor) as typeof config.token)
      : config.token,

    ...configOverrides,
  };
}
