import configuration from 'player/config';
import { createElement } from 'player/plugins/anti-adblock/dom';
import locale from 'player/model/locale';
import logger from 'utils/logger';
import { ensureIfControlsLoaded } from './jw-controls';

export const TTS_SKIN_CLASS_NAME = 'jw-skin-svp-tts';

const JW_AUDIO_MODE_MAX_HEIGHT = '44';

const DEFAULT_TTS_PLAYBACK_RATES = [0.8, 1, 1.25, 1.5, 2, 2.5, 3];

/**
 * Extends JW Player config with a particular height and no controls
 * @param {Object} jwConfig
 * @param {Stream} stream
 * @param {Config} config - playback config
 */
export function applyTtsOption(jwConfig, stream, config) {
    if (!config.shouldApplyTextToSpeechSkin(stream)) {
        return jwConfig;
    }

    // extend config with no controls and height for text to speech
    const {
        height = JW_AUDIO_MODE_MAX_HEIGHT,
        playbackRates = DEFAULT_TTS_PLAYBACK_RATES,
        playbackRateControls = true,
    } = config.getRaw();

    config.set('height', height);

    return {
        ...jwConfig,
        height,
        playbackRates,
        playbackRateControls,
    };
}

/**
 * @param {...string} names - class names without text-to-speech skin prefixes
 */
function classNames(...names) {
    return names.map((name) => `svp-tts-${name}`).join(' ');
}

/**
 *
 * @param {HTMLDivElement} container
 * @param {Object} translate
 */
function createControls(container, translate) {
    const durationText = createElement('span', {
        class: classNames('duration'),
    });
    const durationReplayText = createElement('span', {
        class: classNames('duration'),
    });

    const idleText = createElement(
        'div',
        { class: classNames('text', 'idle-text') },
        [createElement('strong', {}, [translate.play]), ' • ', durationText],
    );

    const replayText = createElement(
        'div',
        { class: classNames('text', 'replay-text') },
        [
            createElement('strong', {}, [translate.replay]),
            ' • ',
            durationReplayText,
        ],
    );

    [idleText, replayText].forEach((el) => {
        container.querySelector('.jw-button-container').appendChild(el);
    });

    return {
        durationText,
        durationReplayText,
    };
}

/**
 *
 * @param {HTMLDivElement} container
 * @param {Object} translate
 */
function createAdElements(container, translate) {
    const altText = createElement(
        'div',
        { class: 'jw-text jw-reset-text svp-tts-text-alt' },
        [translate.cuetext],
    );

    container
        .querySelector('.jw-button-container')
        .insertBefore(
            altText,
            container.querySelector('.jw-button-container .jw-text-elapsed'),
        );
}

/**
 * @param {number} n
 * @param {string} singular
 * @param {string} plural
 */
function pluralize(n, singular, plural) {
    return n === 1 ? singular : plural;
}

/**
 * @param {number} duration
 * @param {Object} translate
 */
function timeToText(duration, translate) {
    const minutes = Math.floor(duration / 60);
    if (minutes) {
        return `${minutes} ${pluralize(
            minutes,
            translate.minute,
            translate.minutes,
        )}`;
    }

    const seconds = Math.floor(duration - minutes * 60);
    return `${seconds} ${pluralize(
        seconds,
        translate.second,
        translate.seconds,
    )}`;
}

let fontLoadingPromise;

async function loadFonts() {
    if (fontLoadingPromise) {
        return fontLoadingPromise;
    }

    const fonts = Object.entries(configuration.cdn.fonts.Graphik).map(
        async ([weight, url]) => {
            const font = new FontFace('Graphik', `url(${url})`, { weight });
            document.fonts.add(font);
            return font.load();
        },
    );

    fontLoadingPromise = Promise.all(fonts);
    return fontLoadingPromise;
}

/**
 * @param {PlayerModel} svpPlayer
 */
function replacePlayerPoster(svpPlayer) {
    const video = svpPlayer.getContainer().querySelector('video.jw-video');
    if (!video) {
        return;
    }
    video.setAttribute('poster', configuration.cdn.poster);
}

/**
 * @param {UIEvent} event
 */
function closeSettingsMenu(event) {
    if (!event.target.classList.contains('jw-settings-content-item')) {
        return;
    }

    const controls = event.currentTarget.querySelector('.jw-controls');
    if (!controls?.classList.contains('jw-settings-open')) {
        return;
    }

    const settingsMenu = event.currentTarget.querySelector(
        '.jw-icon-settings.jw-settings-submenu-button',
    );
    if (!settingsMenu) {
        return;
    }
    event.preventDefault();
    event.stopPropagation();
    settingsMenu.click();
}

/**
 * Resolves whether the player needs a text-to-speech skin or not and replaces UI elements
 * @param {PlayerModel} svpPlayer
 */
export function ttsSkinResolver(svpPlayer) {
    const useTextSpeechSkin = svpPlayer.config.shouldApplyTextToSpeechSkin();
    logger('TTS').log(useTextSpeechSkin ? 'on' : 'off');

    if (!useTextSpeechSkin) {
        return;
    }

    loadFonts();

    const translate = locale.translate('tts');
    let elements;

    const updateDetailTexts = (time, duration) => {
        if (!elements.durationText.textContent.length) {
            elements.durationText.textContent = timeToText(duration, translate);
        }

        if (!elements.durationReplayText.textContent.length) {
            elements.durationReplayText.textContent = timeToText(
                duration,
                translate,
            );
        }
    };

    svpPlayer.on('time', (time, duration) => {
        updateDetailTexts(time, duration);
    });

    svpPlayer.on('ready', () => {
        svpPlayer.getContainer()?.addEventListener('click', closeSettingsMenu);
    });

    svpPlayer.on('playlistItem', async () => {
        const container = svpPlayer.getContainer();

        if (container) {
            await ensureIfControlsLoaded(container);
        }

        const buttonContainer = container?.querySelector(
            '.jw-button-container',
        );

        if (!container || !buttonContainer) {
            return;
        }

        container.classList.add(TTS_SKIN_CLASS_NAME);

        replacePlayerPoster(svpPlayer);
        if (!elements) {
            elements = createControls(container, translate);
            createAdElements(container, locale.translate('ads'));
        }

        const time = svpPlayer.getCurrentTime();
        const duration = svpPlayer.getStream().getDuration();
        updateDetailTexts(time, duration);
    });
}
