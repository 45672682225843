import { Provider } from '@vgtv/api-client';
import type Tracker from '@spt-tracking/pulse-sdk';
import type { Player } from '@schibsted-svp/web-player';

import { resolveWithTimeout } from '../../utils/async';
import { getPulseActor } from '../pulse';
import { getUserIdAsync, getUserAccesses } from '../paywall/spid';
import { getAssetProvider, getConfigProvider } from '../../utils/player/player';

import { GetAdnSettings } from './types';

const DEFAULT_TIMEOUT_IN_MILLISECONDS = 2500;

const ppidSources = {
  [Provider.VGTV]: {
    envHash: 'SCHNO-EnvHash',
    userHash: 'SCHNO-UserHash',
  },
  [Provider.AB]: {
    envHash: 'SCHSE-EnvHash',
    userHash: 'SCHSE-UserHash',
  },
} as const;

const getXandrPPIDs = async (pulse: Tracker) => {
  try {
    const { xandr } = await resolveWithTimeout({
      promise: pulse.getAdvertisingIdentifiers(['xandr']),
      fallback: {},
      timeout: DEFAULT_TIMEOUT_IN_MILLISECONDS,
    });

    return { ppid1: xandr?.ppId1, ppid2: xandr?.ppId2 };
  } catch {
    return {};
  }
};

const generateXandrEids = (
  provider: Provider,
  ppid1?: string,
  ppid2?: string
) =>
  [
    { id: ppid1!, source: ppidSources[provider].userHash },
    { id: ppid2!, source: ppidSources[provider].envHash },
  ].filter(({ id }) => Boolean(id));

const generateXandrKeywords = (ppid1?: string, ppid2?: string) =>
  [
    { id: ppid1, keyword: 'aa-sch-schuserhash' },
    { id: ppid2, keyword: 'aa-sch-schenvhash' },
  ].reduce(
    (obj, { id, keyword }) => ({
      ...obj,
      [keyword]: id ? '1' : '0',
    }),
    {} as Record<string, string>
  );

const getPulseTracker = async (player: Player) => {
  const plugin = player.getPlugin('PulseStats');

  if (plugin) {
    return plugin.getTrackerOrNull();
  }

  if (window.pulse) {
    const provider = getConfigProvider(player);

    const PROVIDER_TRACKER_ID = `svp-tracker-${provider}`;

    window.pulse('init', provider, undefined, undefined, PROVIDER_TRACKER_ID);

    return new Promise<Tracker>((resolve) => {
      window.pulse!(PROVIDER_TRACKER_ID, (tracker) => {
        resolve(tracker);
      });
    });
  }

  return null;
};

let ppids: { ppid1?: string; ppid2?: string } | undefined;

export const withPPID =
  (getSettings: GetAdnSettings): GetAdnSettings =>
  async (context, options, stream, defaults, player) => {
    const provider = getAssetProvider(player);
    if (provider === 'brandstudio') {
      return null;
    }

    const [settings, pulse] = await Promise.all([
      getSettings(context, options, stream, defaults, player),
      resolveWithTimeout({
        promise: getPulseTracker(player),
        fallback: null,
        timeout: DEFAULT_TIMEOUT_IN_MILLISECONDS,
      }),
    ]);

    if (!pulse) {
      return settings || null;
    }

    // make sure pulse tracker has the user data already
    if (!pulse.builders.actor) {
      pulse.update({
        actor: getPulseActor(provider, getUserIdAsync, getUserAccesses),
      });
    }

    if (!ppids) {
      // fetch and store PPIDs even for mock asset
      ppids = await getXandrPPIDs(pulse);
    }

    if (!settings) {
      return null;
    }

    const { ppid1, ppid2 } = ppids;

    const eids = generateXandrEids(provider, ppid1, ppid2);
    const keywords = generateXandrKeywords(ppid1, ppid2);

    // PPID for VAST
    settings.adSlots.params = {
      ...settings.adSlots.params,
      ...keywords,
      eids: eids.map(({ source, id }) => `${source},${id}`).join('|'),
    };

    // PPID for display ads
    if (settings.adPlacements) {
      settings.adPlacements.params.keywords = {
        ...settings.adPlacements.params.keywords,
        ...keywords,
      };
    }

    // PPID for Relevant Yield
    if (settings.relevantYield) {
      settings.relevantYield.eids = eids;
    }

    return settings;
  };
