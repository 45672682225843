import { initialize } from '@vgtv/device-utils';

export const device = initialize();

export const {
  isIPad,
  isIOS,
  isAndroid,
  isMobile,
  isTablet,
  isHermesApp,
  isAftonbladetApp,
  isVgApp,
  deeplinksSupported,
  isHighDpiDevice,
  isTouchDevice,
  isSafari,
} = device;

export const getDeviceType = () => {
  if (isTablet) {
    return 'tablet';
  }

  if (isMobile) {
    return 'mobile';
  }

  return 'desktop';
};
