import { TcfApi } from '../../types/global';

const getTcfConsentString = (tcfapi: TcfApi) => {
  return new Promise<string>((resolve) => {
    tcfapi('addEventListener', 2, (tcdata, success) => {
      if (!success) {
        // callback could not be registered as a listener
        resolve('');
      } else if (
        tcdata.eventStatus === 'tcloaded' ||
        tcdata.eventStatus === 'useractioncomplete'
      ) {
        resolve(tcdata.tcString ?? '');

        tcfapi('removeEventListener', 2, () => {}, tcdata.listenerId);
      }
    });
  });
};

export const getTcfConsentKeywords = async (): Promise<
  Record<string, string>
> => {
  if (!window.__tcfapi) {
    return {};
  }

  return {
    gdpr: '1',
    gdpr_consent: await getTcfConsentString(window.__tcfapi),
  };
};
