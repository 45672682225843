import config from 'player/config';

import { TOKEN_FETCH_ERROR } from '../playback/config/status-codes';

/**
 * @param {{expiry: number, value: string, vendor: string}} params
 * @returns {Promise<string>}
 */
const fetchToken = async function (params) {
    this.isFetching = true;

    const response = await fetch(this.url(params));

    this.isFetching = false;

    if (!response.ok) {
        return Promise.reject(TOKEN_FETCH_ERROR);
    }

    this.value = await response.text();
    this.expire = Date.now() + 1000;
    return this.value;
};

class Token {
    /**
     * @param {number} assetId
     * @param {string} [assetAccess]
     * @param {number} [maxBitrate]
     */
    constructor(assetId, assetAccess, maxBitrate) {
        /**
         * Id of SVP related asset
         *
         * @type {number}
         */
        this.assetId = assetId;

        /**
         * An access level key string
         * See https://svp.vg.no/svp/api/v1/vgtv/assets/access-definitions?appName=docs for available levels
         *
         * @type {string}
         */
        this.assetAccess = assetAccess;

        /**
         * Akamai token value retrieved from SVP api
         * @type {string|null}
         */
        this.value = null;
        /**
         * Expiration time, timestamp
         * @type {number|null}
         */
        this.expire = null;

        /**
         * Maximum allowed bitrate for stream
         * @type {number|null}
         */
        this.maxBitrate = maxBitrate || null;

        /**
         * @type {Promise<string>|null}
         */
        this.request = null;
        this.isFetching = false;
    }

    /**
     * @param {((assetId: number, assetAccess: string) => Promise<{expiry: number, value: string} | {} | null>)} [configuration]
     * @param {boolean} hasAccess
     * @param {string} vendor
     * @returns {Promise<string>|null}
     */
    async fetch(configuration, hasAccess, vendor) {
        if (!this.isValid()) {
            if (this.request && this.isFetching) {
                return this.request;
            }

            if (typeof configuration === 'function') {
                const { value, expiry } = await configuration(
                    this.assetId,
                    this.assetAccess,
                );
                if (value && expiry) {
                    this.request = fetchToken.call(this, {
                        expiry,
                        value,
                        vendor,
                    });
                    return this.request;
                }
            }
            if (!hasAccess) {
                this.request = fetchToken.call(this, { vendor });
                return this.request;
            }
            this.request = Promise.resolve(null);
        }

        return this.request;
    }

    /**
     * @param {{expiry: number, value: string, vendor: string}} params
     *
     * @returns {string}
     */
    url(params) {
        const query = [`vendor=${params.vendor}`];

        if (params && params.expiry && params.value) {
            query.push(`assetId=${this.assetId}`);
            query.push(`expires=${params.expiry}`);
            query.push(`hmac=${params.value}`);
        } else {
            query.push('acl=/*');
        }

        if (this.maxBitrate) {
            query.push(`maxBitrate=${this.maxBitrate}`);
        }

        return `${config.api.tokenUrl}?${query.join('&')}`;
    }

    /**
     * @returns {string|null}
     */
    getValue() {
        return this.value;
    }

    /**
     * @returns {boolean}
     */
    isValid() {
        // on load expire is null therefore it will always return false
        return Date.now() < this.expire;
    }
}

export default Token;
