import { Provider } from '@vgtv/api-client';

import type { PlayerConfigOptions } from '../types/global';

import storage, { AUTH_REDIRECT_KEY } from './paywall/sessionStorage';
import { getSkinUrl } from './helpers/staticUrls';

const defaultOptions: Partial<PlayerConfigOptions> = {
  site: 'vgtv',
  noFirstAd: false,
  context: 'embed',
  ads: {
    preroll: true,
    longform: true,
    sponsorroll: true,
    bumper: false,
    midroll: false,
    postroll: false,
    pausead: false,
    sectionKeywords: [],
  },
  plugins: {
    mediaSession: true,
    topicSubscriber: true,
    sponsorOverlay: true,
  },
  paywallAccess: {
    enabled: false,
    message: {
      utmParameters: {
        source: 'vgtv-video',
        term: '',
        content: '',
        medium: '',
      },
      onRedirect: () => {
        storage.set(AUTH_REDIRECT_KEY, window.location.href);
      },
    },
    usePre: false,
  },
};

const defaultConfig = {
  vendor: Provider.VGTV,
  locale: 'no' as const,
  settings: {
    preview: false,
    jw: {
      advertising: {
        podmessage: '__AD_POD_CURRENT__ av __AD_POD_LENGTH__',
      },
      cast: {},
    },
  },
  recommended: {
    grid: true,
    autoplay: false,
  },
  skin: {
    name: 'vgtv2',
    url: getSkinUrl('vgtv2'),
  },
  pulse: {
    provider: Provider.VGTV,
  },
  ageLimit: {
    hideAfter: 10000,
  },
};

export { defaultConfig, defaultOptions };
