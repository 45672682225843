import { scriptLoader } from 'utils/script-loader';

const TRACKER_ID = 'svp-tracker';

const PulseTrackerLoader = {
    request: null,

    trackers: {},

    /**
     * Load pulse library only once per page
     *
     * @returns {null}
     */
    load() {
        if (!this.request) {
            if (!window.pulse) {
                // a global pulse object has to be present to avoid autoloader errors
                window.pulse =
                    window.pulse ||
                    function () {
                        window.pulse.q = window.pulse.q || [];
                        window.pulse.q.push(arguments);
                    };

                this.request = scriptLoader(
                    '//sdk.pulse.schibsted.com/versioned/3.1/pulse.min.js',
                );
            } else {
                // pulse is already available
                this.request = Promise.resolve();
            }
        }

        return this.request;
    },

    init(provider, sdkConfig) {
        return this.load()
            .then(() => this.getTracker(provider))
            .then((storedTracker) => {
                const PROVIDER_TRACKER_ID = `${TRACKER_ID}-${provider}`;

                // initialize pulse only once per page per provider
                if (!storedTracker) {
                    // we need to name the tracker to avoid tracking conflicts when embedding the player
                    window.pulse(
                        'init',
                        provider,
                        {
                            altEventHandler: window.pulseEventHandler,
                            useBeaconWhenAvailable: true,
                            ...sdkConfig,
                        },
                        undefined,
                        PROVIDER_TRACKER_ID,
                    );

                    const promise = new Promise((resolve) => {
                        window.pulse(PROVIDER_TRACKER_ID, (tracker) =>
                            resolve(tracker),
                        );
                    });

                    PulseTrackerLoader.trackers[provider] = promise;

                    return promise;
                }

                return storedTracker;
            });
    },

    getTracker(provider) {
        return PulseTrackerLoader.trackers[provider] || Promise.resolve(null);
    },
};

export default PulseTrackerLoader;
