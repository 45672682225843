import utils from 'utils/utils';
import logger from 'utils/logger';
import ServerTime from 'utils/server-time';
import { warnOnce } from 'utils/warn-once';
import config from 'player/config';
import locale from './model/locale';
import Asset from './model/asset';
import Config from './playback/config';
import Domain from './model/domain';
import Recommended from './model/recommended';
import { APPNEXUS_PROVIDER_ID } from './services/api';
import { getMockAsset } from './services/api/asset';
import PulseTracker from './plugins/pulse-stats/tracker';

import { onAssetError } from './events/error';
import { onAssetReady } from './events/asset';
import { onComplete } from './events/complete';
import { onceConfigReady } from './events/config';
import { destroyPlugin } from './events/helpers';

/**
 * @constructor
 * @param {Config} configuration
 */
const Player = function (configuration) {
    const options = { ...configuration };

    // sync browser time with server
    if (!options.serverTime) {
        ServerTime.fetch(config.time);
    }

    // Little bit of monkey patching
    config.api.vendor = options.vendor || 'vgtv';
    config.env =
        config.env === 'production'
            ? 'production'
            : options.env || config.env || 'production';

    // Restrict player on blacklisted domains
    if (Domain.isBlacklisted(options.vendor)) {
        return;
    }

    logger('SVP').log('config', utils.extend({}, options));

    /**
     * Allow to embed player without asset
     * This can be useful for preloading player
     * Appnexus vendor is for displaying ads only playback
     */
    if (
        options.vendor === APPNEXUS_PROVIDER_ID ||
        options.id === -1 ||
        (!options.id && !options.asset)
    ) {
        options.asset = getMockAsset();
        delete options.id;
    }

    // load locales for player
    // norwegian by default
    if (typeof options.locale === 'string') {
        locale.setTranslations(config.translations(options.locale));
    }

    // bind player api to pass it as last argument in adn function
    if (options.adn) {
        options.adn.svpPlayer = this;
    }

    // create configuration for SVP player
    this.config = new Config();
    this.listenToOnce(this.config, 'ready', onceConfigReady, this);

    this.config.initialize(options);

    // set dynamic api url
    if (options.api) {
        config.api.url = options.api;
    }

    // set dynamic related api url
    if (options.relatedUrl) {
        config.api.relatedApiUrl = options.relatedUrl;
    }

    // allow to override token api url
    if (options.tokenUrl) {
        config.api.tokenUrl = options.tokenUrl;
    } else if (options.api) {
        config.api.tokenUrl = options.api.replace('api/v1/', 'token/v1/');
    }

    // allow to override thumbnails api url
    if (options.thumbnailsUrl) {
        config.api.thumbnailsUrl = options.thumbnailsUrl;
    } else if (options.api) {
        config.api.thumbnailsUrl = options.api.replace(
            'api/v1',
            'thumbnails/v1',
        );
    }

    if (options.vmapUrl) {
        config.api.vmapUrl = options.vmapUrl;
    }

    /**
     * Available plugins
     * PausePlugin, EndposterPlugin, ChaptersPlugin, ContinuePlayingPlugin
     *
     * Plugins registered by default (can not be removed)
     * CountdownPlugin, AgeLimitPlugin
     * @type {Object}
     */
    this.plugins = {};

    /**
     * Check if stream has completed playback
     * @type {boolean}
     */
    this.isCompleted = false;

    this.isPlayNextAvailable = true;

    // fetch config for privileged settings to speedup setup
    if (options.settings) {
        Domain.fetch(options.vendor);
    }

    /**
     * Prefetch Pulse tracking library
     */
    if (options.pulse?.provider) {
        PulseTracker.load();
    }

    logger('SVP').log(
        function (message) {
            this.on('all', function (event) {
                message(event, Array.prototype.slice.call(arguments, 1));
            });
        }.bind(this),
    );
};

/**
 * Player public API
 */
Player.prototype = {
    /**
     * Play
     * @param {number} position - seconds
     */
    play(position) {
        // stream will play only if publication date is valid
        const stream = this.model.getStream();
        if (stream.getTimeToStart() < 0 && !stream.isPast()) {
            if (position) {
                this.once('play', this.seek.bind(this, position));
            }

            this.model.play();
        }
    },

    /**
     * Pause playback
     * @param {boolean} [force] - toggle playback when param is omitted
     */
    pause(force) {
        // pause with force can start stream which is wrong
        if (this.model.getStream().getTimeToStart() < 0) {
            this.model.pause(!force);
        }
    },

    /**
     * Seek in seconds
     * @param {number} time
     */
    seek(time) {
        this.model.seek(time);
    },

    /**
     * Destroy the player instance, reset DOM, clean up listeners
     */
    remove() {
        utils.each(this.plugins, destroyPlugin, this);
        this.stopListening();

        if (this.model) {
            this.model.remove();
        }
    },

    /**
     * Play next asset by given id
     *
     * @param {number} id
     * @param {Object} [options]
     * @param {Object} [options.pulse]
     * @param {number} [options.time]
     * @param {boolean} [options.disableAutoplay]
     */
    playNext(id, options = {}) {
        const onNextAssetReady = function () {
            const stream = this.model.getStream();
            const { disableAutoplay, time } = options;

            if (time) {
                stream.set('playAhead', time);
            }

            // trick to avoid create of new stream as it's set in assetReady
            this.model.playNext(stream, { disableAutoplay });
            this.isPlayNextAvailable = true;
        }.bind(this);

        if (this.isPlayNextAvailable) {
            const reason = options.pulse?.playbackSource || 'manualNext';
            // complete current stream
            onComplete.call(this, reason);

            // block multiple occurences
            this.isPlayNextAvailable = false;

            this.once('assetReady', onNextAssetReady, this);

            this.once(
                'assetError',
                function () {
                    if (this.model?.player) {
                        this.pause();
                    }

                    this.isPlayNextAvailable = true;
                    this.off('assetReady', onNextAssetReady);
                },
                this,
            );

            // trigger play next always after complete to keep event order the same
            // for videos which completed or has been interrupted with play next
            this.trigger('playNext', id, options);
            this.setAsset(id);
        }
    },

    /**
     * Set playback rate
     *
     * @param {number} rate - Accepts any numeric value between 0.25 and 4. The playback rate is clamped if rate is out of range.
     */
    setPlaybackRate(rate) {
        this.model.setPlaybackRate(rate);
    },

    /**
     * Get playback rate
     * @returns {number}
     */
    getPlaybackRate() {
        return this.model.getPlaybackRate();
    },

    /**
     * Set playback volume
     *
     * @param {number} volume - number between 0 and 100
     */
    setVolume(volume) {
        this.model.setVolume(volume);
    },

    /**
     * Get playback volume
     * @returns {number}
     */
    getVolume() {
        return this.model.getVolume();
    },

    /**
     * Set mute value for playback
     * Toggling this param will preserve volume value
     *
     * @param {boolean} [value=true]
     */
    setMute(value = true) {
        this.model.setMute(value);
    },

    /**
     * Get playback muted state
     * @returns {boolean}
     */
    getMute() {
        return this.model.getMute();
    },

    /**
     * Get player state
     * @returns {string}
     */
    getState() {
        return this.model.getState();
    },

    /**
     * @returns {boolean}
     */
    getFloating() {
        return this.model.getFloating();
    },

    /**
     * @param {boolean} shouldFloat
     */
    setFloating(shouldFloat) {
        return this.model.setFloating(shouldFloat);
    },

    /**
     * Get stream duration
     *
     * @returns {number}
     */
    getDuration() {
        return this.model.getDuration();
    },

    /**
     * Get current playback time
     * @returns {number}
     */
    getCurrentTime() {
        return this.model.getCurrentTime();
    },

    /**
     * Get current captions list
     * @returns {{ id: string; label: string; language?: string; default?: boolean }[]}
     */
    getCaptionsList() {
        return this.model.getCaptionsList();
    },

    /**
     * Get currently playing captions
     * @returns {number}
     */
    getCurrentCaptions() {
        return this.model.getCurrentCaptions();
    },

    /**
     * Set current captions by passing its index
     * Setting 0 will hide all captions
     * @param {number} index
     */
    setCurrentCaptions(index) {
        return this.model.setCurrentCaptions(index);
    },

    /**
     * Get Player DOM Node
     *
     * @returns {HTMLElement|null}
     */
    getContainer() {
        return this.model.getContainer();
    },

    /**
     * @returns {jwplayer.JWPlayer}
     */
    getJWPlayer() {
        return this.model.getPlayer();
    },

    getRawConfig() {
        return this.config.getRaw();
    },

    /**
     * Get current server time
     *
     * @return {number}
     */
    getServerTime() {
        return ServerTime.getTime();
    },

    /**
     * @returns {Promise<void>}
     */
    stop() {
        return this.model.stop();
    },

    /**
     * Set asset resource
     * @param {Asset|number} asset - Asset model or stream id
     */
    async setAsset(asset) {
        // cleanup old asset
        if (this.asset) {
            this.stopListening(this.asset);
            this.asset.destroy();
            this.asset = null;
        }

        // change asset to object if it is a number
        if (utils.isNumber(asset)) {
            // eslint-disable-next-line no-param-reassign
            asset = {
                id: asset,
            };
        }

        this.asset = asset instanceof Asset ? asset : new Asset(asset);

        // set vendor for player's default if nothing has been passed
        if (!this.asset.getVendor()) {
            this.asset.set('vendor', this.config.get('vendor'));
        }

        // asset is ready if status field is in response
        if (this.asset.get('status')) {
            onAssetReady.call(this);
        } else {
            try {
                await this.asset.fetch();
                onAssetReady.call(this);
            } catch (error) {
                onAssetError.call(this, error);
            }
        }
    },

    /**
     * @returns {Asset|null}
     */
    getAsset({ shouldLogDeprecatedWarning = true } = {}) {
        if (shouldLogDeprecatedWarning) {
            warnOnce(
                'get-asset',
                'SVP Player SKD deprecation warning\ngetAsset will be removed in version @schibsted-svp/web-player@4.0.0\nPlease use getRawAsset instead',
            );
        }
        return this.asset;
    },

    /**
     * @returns {import('@schibsted-svp/svp-api-types').Asset|undefined}
     */
    getRawAsset() {
        return this.asset.getRawAsset();
    },

    /**
     * Get next asset
     * @return {Promise<Asset|null>}
     */
    async getNextAsset() {
        const { next } = this.config.getRecommended() || {};
        if (next === false) {
            return null;
        }

        const recommendedPlugin = this.getPlugin('RecommendedPlugin');
        if (!recommendedPlugin) {
            return null;
        }
        if (recommendedPlugin.nextAsset) {
            return recommendedPlugin.nextAsset;
        }

        const recommended = new Recommended({
            asset: this.getAsset({ shouldLogDeprecatedWarning: false }),
        });
        const { nextAsset, source } = await recommended.getNextAsset(next);
        recommendedPlugin.nextAsset = nextAsset;
        recommendedPlugin.nextAssetSource = source;

        return recommendedPlugin.nextAsset;
    },

    /**
     * Add plugin to the player
     *
     * @param {PluginModel} plugin
     */
    addPlugin(plugin) {
        const proxyPluginEvent = function (event, ...args) {
            this.trigger(`${plugin.getName()}:${event}`, ...args);
        };

        plugin.setPlayer(this);
        this.listenTo(plugin, 'all', proxyPluginEvent, this);

        this.plugins[plugin.getName()] = plugin;
    },

    /**
     * Get plugin by name
     *
     * @param {string} plugin - name of the plugin
     * @returns {PluginModel|undefined}
     */
    getPlugin(plugin) {
        return this.plugins[plugin];
    },
};

/**
 * Checks if device can autoplay stream
 * @deprecated new autoplay policies require starting of video to detect if autoplay will work
 * @returns {boolean}
 */
Player.canDeviceAutoplay = function () {
    // eslint-disable-next-line no-console
    if (console?.warn) {
        // eslint-disable-next-line no-console
        console.warn(
            'SVP Player SKD deprecation warning. ' +
                'Due to changes in browser policies, player will detect itself if it can autoplay.',
        );
    }

    return utils.device.canAutoplay();
};

utils.extend(Player.prototype, utils.Events);

export default Player;
